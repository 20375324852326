<template>
  <div id="page-service">
    <v-container grid-list-xl fluid>
      <v-card>
        <v-card-title>{{ $vuetify.lang.t('$vuetify.different.service') }}</v-card-title>
        <v-card-text>
          <v-btn
            class="ma-2"
            :loading="clearCacheLoading"
            :disabled="clearCacheLoading"
            color="primary"
            @click="clearCache"
          >
            {{ $vuetify.lang.t('$vuetify.different.clearCache') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.show = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

export default {
  data() {
    return {
      clearCacheLoading: false,
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.different.service')
    }
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    }
  },
  methods: {
    clearCache() {
      this.clearCacheLoading = true
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/service/cache',
          {},
          this.compAuthHeader
        )
        .then(() => {
          this.clearCacheLoading = false
          this.showSnackbar(
            'green',
            this.$vuetify.lang.t('$vuetify.messages.cacheCleared')
          )
        })
        .catch(() => {
          this.clearCacheLoading = false
          this.showSnackbar(
            'red',
            this.$vuetify.lang.t('$vuetify.errors.error')
          )
        })
    },
    showSnackbar(color, text) {
      this.snackbar.show = true
      this.snackbar.color = color
      this.snackbar.text = text
    }
  }
}
</script>
